import React from 'react';
import { ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router-dom';

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function SideButtons({showComplete, showTodo, refreshDb}) {

  const classes = useStyles();

  const handleClick = () => {
    history.push('/addcard');
  }
 
  const history = useHistory();

  return (
    <>
    {/* ADD MODAL HERE */}
      <ListItem button onClick={handleClick} className={classes.iconBtn}>
        <ListItemIcon><AddBoxIcon/></ListItemIcon>
        <ListItemText>Add new task</ListItemText>
      </ListItem>
      
      <ListItem button onClick={showComplete} className={classes.iconBtn}>
        <ListItemIcon><PlaylistAddCheckIcon/></ListItemIcon>
        <ListItemText>Completed tasks</ListItemText>
      </ListItem>

      <ListItem button onClick={showTodo} className={classes.iconBtn}>
        <ListItemIcon><FormatListBulletedIcon/></ListItemIcon>
        <ListItemText>Tasks to do</ListItemText>
      </ListItem>

      <ListItem button onClick={refreshDb} className={classes.iconBtn}>
        <ListItemIcon><RefreshIcon/></ListItemIcon>
        <ListItemText>Refresh DB</ListItemText>
      </ListItem>
    </>
  )
}
