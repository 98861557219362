import React from 'react'
import { Box, Grid } from '@material-ui/core'
import MemCard from './card'


export default function ShowCards( {myCards} ) {


  return (
    <Box p={2}> 
      <Grid container 
        spacing={2}
        alignItems="center"
        justifyContent="space-evenly"
        >
                
        { myCards && myCards.map(card =>
          <Grid item xs={12} sm={8} md={6} spacing={3}>
            <MemCard id={card.id} title={card.title} description={card.description} deadline={ card.deadline } category={card.category} complete={card.complete}/>
          </Grid>  
          )
        }
      </Grid>
    </Box>
  )
}